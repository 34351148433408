



































































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Chart from "@/components/Charts/Chart.vue";
import { ChartData, ChartOptions } from "chart.js/auto";
import PrintButton from "@/components/Button/PrintButton.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import moment from "moment";
import { formatInstructorName } from "../../utils/NameUtil";

@Component({
  methods: { formatInstructorName },
  components: { Chart, FscSimpleCard, PrintButton, DocumentButton, Hint },
})
export default class ReportFinancesChart extends Vue {
  public name = "ReportFinancesChart";

  @Prop()
  public data!: any;

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop()
  public filterData!: any;

  @Prop()
  public filterInfo!: any;

  @Prop()
  public selectedType!: any;

  public get value() {
    if (!this.data) return [];
    const arr: any = [];
    if (this.selectedType.id === 1) {
      for (const [key, value] of Object.entries(this.data)) {
        arr.push(value);
      }
    } else if (this.selectedType.id === 2) {
      for (const [key, value] of Object.entries(this.data)) {
        arr.push({ x: moment(key).format("DD.MM.YYYY"), y: value });
      }
    }
    return arr;
  }

  public get labels() {
    if (!this.data) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.data)) {
      arr.push(key);
    }
    return arr;
  }

  public chartColors = ["#169FDB", "#793b86", "#dc0c23", "#ea5b1b", "#f9b342"];

  public get chartData(): ChartData {
    return {
      datasets: [
        {
          fill: false,
          showLine: true,
          tension: 0.3,
          label: "",
          data: this.value,
          backgroundColor: this.chartColors[0],
          borderColor: this.chartColors[0],
          pointBackgroundColor: this.chartColors[0],
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    };
  }

  public chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        ticks: {
          stepSize: 1,
          font: {
            weight: "bold",
            size: 10,
          },
        },
        beginAtZero: true,
      },
      x: {
        ticks: {
          font: {
            weight: "bold",
            size: 10,
          },
        },
      },
    },
  };

  public randomColorGenerator(value: any) {
    const colors = value.map((val: any) => {
      return "#" + (Math.random().toString(16) + "0000000").slice(2, 8);
    });
    return colors;
  }

  public get chartZahlungenData(): ChartData {
    return {
      labels: this.labels,
      datasets: [
        {
          label: this.value,
          data: this.value,
          backgroundColor: this.chartColors,
          hoverOffset: 20,
          borderWidth: 3,
        },
      ],
    };
  }

  public chartZahlungenOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    radius: 160,
    aspectRatio: 2,
    maintainAspectRatio: true,
    layout: {
      padding: 50,
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };
}
